<template>
  <div>
    <vx-card>
      <vs-row>
        <vs-col>
          <h4 class="mr-4">G Meet Approval</h4>
        </vs-col>
      </vs-row>
      <vs-row vs-w="12" vs-type="flex" vs-justify="flex-start" vs-align="center" class="mt-10">
        <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="2">
          <vs-dropdown class="dropp-down" vs-custom-content vs-trigger-click>
            <a class="a-icon a-iconn" href.prevent @click="showTreeBox">
              Team
              <i class="material-icons">expand_more</i></a>
            <vs-dropdown-menu class="customDropDown" style="position: absolute;z-index: 1000000;">
              <v-select class=" invc-input" placeholder="Select..." v-model="selected_team" :options="teams" :disabled="team_dropdown">
              </v-select>
            </vs-dropdown-menu>
          </vs-dropdown>
        </vs-col>
        <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="2">
          <vs-dropdown class="dropp-down" vs-custom-content vs-trigger-click>
            <a class="a-icon a-iconn" href.prevent @click="showTreeBox">
              Spocs
              <i class="material-icons">expand_more</i></a>
            <vs-dropdown-menu class="customDropDown">
              <treeselect :value-consists-of="'LEAF_PRIORITY'" v-model="selected_spoc_ids" :multiple="true" :options="spoc_tree_data" />
            </vs-dropdown-menu>
          </vs-dropdown>
        </vs-col>
        <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="2">
          <vs-dropdown vs-custom-content vs-trigger-click class="dropp-down">
            <a class="a-icon a-iconn" href.prevent @click="showTreeBox">
              Status
              <i class="material-icons">expand_more</i>
            </a>
            <vs-dropdown-menu class="customDropDown">
              <v-select class=" invc-input" placeholder="Select..." v-model="selected_status" :options="tree_data_status">
              </v-select>
            </vs-dropdown-menu>
          </vs-dropdown>
        </vs-col>
        <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="2.5">
          <date-range-picker ref="picker" :opens="'left'" :locale-data="{
            firstDay: 1,
            format: 'DD-MMM-YYYY',
            customRangeLabel: 'Custom Range',
          }" :minDate="null" :maxDate="null" :singleDatePicker="false" :timePicker="false" :timePicker24Hour="true"
            :showWeekNumbers="false" :showDropdowns="false" :autoApply="false" v-model="dateRange"
            style="width: 240px; margin-right: 2%"></date-range-picker>
        </vs-col>
        <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="1.25">
          <vs-input placeholder="can-id" v-model="can_id"></vs-input>
        </vs-col>
        <vs-col vs-type="flex" vs-justify="flex-end" vs-align="center" vs-w="1.25">
          <vs-button radius class="gap" color="dark" type="gradient" icon="search" @click="getGmeetData(1)"></vs-button>
          &nbsp;
          <vs-button radius class="gap" color="dark" type="gradient" icon="refresh"
            @click="refreshFilterData"></vs-button>
        </vs-col>
      </vs-row>
      <vs-row style="margin-top: 2%">
        <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="2">
          <b>Search Criteria:</b>
        </vs-col>
        <vs-col style="overflow-y: scroll" vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="9">
          <div v-for="(chip, index) in search_criteria" :key="index">
            <vs-chip v-if="chip !== 'divider'" color="primary">
              <b>{{ chip }}</b>
            </vs-chip>
            <div v-else>
              <div class="vl"></div>
            </div>
          </div>
        </vs-col>
      </vs-row>
      <br />
      <vs-table :data="Gmeet_data" class="mt-3">
        <template slot="thead">
          <vs-th> Can-ID </vs-th>
          <vs-th> Name </vs-th>
          <vs-th> SPOC Name </vs-th>
          <vs-th> Scheduled Date</vs-th>
          <vs-th> Start Time </vs-th>
          <vs-th> End Time </vs-th>
          <vs-th> Spoc Duration </vs-th>
          <vs-th> Candidate Duration </vs-th>
          <vs-th> Final Duration </vs-th>
          <vs-th> Status </vs-th>
          <vs-th> Actions </vs-th>
        </template>

        <template slot-scope="{ data }">
          <vs-tr :key="indextr" v-for="(tr, indextr) in data">
            <vs-td :data="data[indextr].identity">
              {{ data[indextr].identity }}
            </vs-td>
            <vs-td :data="data[indextr].candidate_name">
              {{ data[indextr].candidate_name }}
            </vs-td>
            <vs-td :data="data[indextr].first_name">
              {{ data[indextr].first_name }} {{ data[indextr].last_name }}
            </vs-td>
            <vs-td :data="data[indextr].scheduled_date">
              {{ unixDateConvert(data[indextr].scheduled_date) }}
            </vs-td>
            <vs-td :data="data[indextr].scheduled_date">
              {{ unixTimestampConvert(data[indextr].scheduled_date) }}
            </vs-td>
            <vs-td :data="data[indextr].ended_date">
              {{ unixTimestampConvert(data[indextr].ended_date) }}
            </vs-td>
<vs-td :data="data[indextr].spoc_duration">
              {{ getMinutes(data[indextr].spoc_duration) }}
            </vs-td>
            <vs-td :data="data[indextr].candidate_duration">
              {{ getMinutes(data[indextr].candidate_duration) }}
            </vs-td>
            <vs-td :data="data[indextr].duration_seconds">
              {{ getMinutes(data[indextr].duration_seconds) }}
            </vs-td>
            <vs-td :data="data[indextr].approval_status">
              {{ data[indextr].approval_status }}
            </vs-td>
            <vs-td>
              <vs-row>
                <div @click="openPopupEmit(tr)">
                  <vs-icon icon="info_outline" size="small" color="success"></vs-icon>
                </div>

                <div @click="changeStatus(tr)" v-if="logged_in_team != 'CM' && logged_in_team != 'SR'">
                  <vs-icon v-if="data[indextr].approval_status == 'pending'" icon="warning_outline" size="small" color="warning"></vs-icon>
                </div>
              </vs-row>
            </vs-td>
          </vs-tr>
        </template>
      </vs-table>
      <vs-pagination class="mt-2" :total="tablelinks" v-model="currentTablePage"></vs-pagination>
    </vx-card>
    <vs-popup classContent="holamundo schedule_popup" title="Gmeet Approval" :active.sync="show_status_popup">
      <vs-row>
        <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="12">
          <b style="color: gray">Approve the following Gmeet entry?</b>
        </vs-col>
      </vs-row>
      <vs-table :data="popup_data" class="mt-3">
        <template slot="thead">
          <vs-th> Can-ID </vs-th>
          <vs-th> Name </vs-th>
          <vs-th> SPOC Name </vs-th>
          <vs-th> Date </vs-th>
          <vs-th> Duration(sec) </vs-th>
          <vs-th> Status </vs-th>
        </template>

        <template slot-scope="{ data }">
          <vs-tr :key="indextr" v-for="(tr, indextr) in data">
            <vs-td :data="data[indextr].identity">
              {{ data[indextr].identity }}
            </vs-td>
            <vs-td :data="data[indextr].candidate_name">
              {{ data[indextr].candidate_name }}
            </vs-td>
            <vs-td :data="data[indextr].first_name">
              {{ data[indextr].first_name }} {{ data[indextr].last_name }}
            </vs-td>
            <vs-td :data="data[indextr].scheduled_date">
              {{ unixDateConvert(data[indextr].scheduled_date) }}
            </vs-td>
            <vs-td :data="data[indextr].duration_seconds">
              {{ getMinutes(data[indextr].duration_seconds) }}
            </vs-td>
            <vs-td :data="data[indextr].approval_status">
              {{ data[indextr].approval_status }}
            </vs-td>
          </vs-tr>
        </template>
      </vs-table>
      <br />
      <vs-row>
        <vs-col vs-type="flex" vs-justify="flex-end" vs-align="center" vs-w="9">
          <vs-button :color="getColor('Approve')" type="filled"
            @click="updateActivityStatus(popup_data[0].google_meet_id, 'Approve')">Approve</vs-button>
        </vs-col>
        <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="3">
          <vs-button :color="getColor('Reject')" type="filled"
            @click="updateActivityStatus(popup_data[0].google_meet_id, 'Reject')">Reject</vs-button>
        </vs-col>
      </vs-row>
    </vs-popup>
  </div>
</template>
  
<script>
import constants from "../../constants.json";
import DateRangePicker from "vue2-daterange-picker";
import VueTimepicker from "vue2-timepicker";
import moment from "moment";
import vSelect from "vue-select";
import axios from "axios";
import Treeselect from "@riophae/vue-treeselect";
import "@riophae/vue-treeselect/dist/vue-treeselect.css";
export default {
  components: {
    VueTimepicker,
    DateRangePicker,
    "v-select": vSelect,
    Treeselect,
  },
  mounted() {
    this.logged_in_spoc_id =  localStorage.getItem("spoc_id")
    this.logged_in_team = localStorage.getItem("team");
    this.spoc_email_id = localStorage.getItem("email_id");
    if (this.admin_access_spocs.includes(this.spoc_email_id)) {
        this.logged_in_team = 'Admin';
    }
    console.log("this.logged_in_team", this.logged_in_team);
    if (this.getTeam("GM") && this.logged_in_team != 'Admin') {
      if (localStorage.getItem('sub_team') === 'CM' && this.logged_in_team == 'GM') {
        this.selected_team = 'CM'
      }else if (localStorage.getItem('sub_team') === 'SR' && this.logged_in_team == 'GM') {
        this.selected_team = 'SR'
      }else if(localStorage.getItem('sub_team') === 'GM' && localStorage.getItem('team') === 'GM'){
        this.selected_team = 'CM'
        return
      }
      this.team_dropdown = true;
      this.getGmReportingSpocs();
    }else if (this.getTeam("CM")){
      this.selected_team = 'CM'
      this.team_dropdown = true;
    }else if (this.getTeam("SR")){
      this.selected_team = 'SR'
      this.team_dropdown = true;
    }else if (this.getTeam("Admin") || this.logged_in_team == 'Admin') {
      this.getGmeetData(1);
    }
  },
  watch: {
    selected_status(val) {
      console.log(val);
      if(val == null){
        this.search_criteria = [];
        this.selected_status = '';
      }
      this.showSearch();
    },
    selected_team(val) {
      this.tablelinks = 0;
      this.Gmeet_data = [];
      this.search_criteria = [];
      this.selected_status = ''
      this.team_wise_spoc_ids = [];
      this.selected_spoc_ids = [];
      if (this.getTeam == 'GM') {
        this.logged_in_team = val;
      }
      console.log(val);
      if (val == 'SR' && this.logged_in_team != 'GM') {
        this.getSpocs('SR');
      } else if (val == 'CM'  && this.logged_in_team != 'GM') {
        this.getSpocs('CM');
      }else if(val == null){
        this.search_criteria = [];
        this.selected_team = '';
      }
      this.showSearch();
    },
    selected_spoc_ids(val) {
      console.log(val);
      this.showSearch();
    },
    currentTablePage(val) {
      this.getGmeetData(val);
    },
  },
  data() {
    return {
      team_dropdown: false,
      spocs_data: [],
      cities_list: [],
      search_criteria: [],
      selected_mwb_id: '',
      can_id: "",
      can_details: [],
      logged_in_spoc_id: "",
      button_status: "",
      show_status_popup: false,
      popup_data: [],
      filtered_sr_utilization: [],
      selected_spoc_ids: [],
      team_wise_spoc_ids: [],
      selected_team: '',
      selected_status: '',
      teams: ['CM', 'SR'],
      tree_data_status: ['approved', 'pending', 'rejected'],
      rawcities: [],
      logged_in_team: "",
      spoc_tree_data: [
        {
          id: "All",
          label: "All",
          children: [],
        },
      ],
      addUtPopup: false,
      popupActivo: false,
      popupActivo3: false,
      selectedFile: null,
      currentTablePage: 1,
      tablelinks: 0,
      Gmeet_data: [],
      status_filter_data: [],
      activity_filter_data: [],
      start_time: {
        hh: null,
        mm: null,
      },
      end_time: {
        hh: null,
        mm: null,
      },
      dateRange: {
        startDate: new Date(),
        endDate: new Date(),
      },
      previewImage: null,
      file_type: null,
      picture: null,
      add_activity: "",
      admin_access_spocs : [
        "kabir.chawla@mileseducation.com",
        "ghafir.samad@mileseducation.com",
        "sharon.j@mileseducation.com",
        "athira.m@mileseducation.com",
        "nidhin.ram@mileseducation.com",
        "ghouse.ahmed@mileseducation.com",
        "punith.rao@mileseducation.com",
        "satyam.bhivgade@mileseducation.com",
        "krishna.kanth@mileseducation.com",
        "satram.chaitanya@mileseducation.com",
        "johnadhoc@example.com",
        "apoorva.agarwal@mileseducation.com",
        "pramod.sharma@mileseducation.com",
        "gagan.jot@mileseducation.com",
        "anjum.taj@mileseducation.com",
        "reshma.johnson@mileseducation.com",
        "simran.basha@mileseducation.com",
      ],
      spoc_email_id: '',
    };
  },
  methods: {
    getGmReportingSpocs() {
      let gm_id = localStorage.getItem("spoc_id");
      let url = `${constants.SERVER_API}getSpocsOfGM?gm_id=${gm_id}`;
      axios
        .get(url, {
          headers: { Authorization: `Bearer ${localStorage.userAccessToken}` },
        })
        .then((response) => {
          console.log("response.data.spocs", response.data.spocs);
          this.spocs_data = response.data.spocs;
          this.cities_list = [...new Set(response.data.spocs.map((item) => item.city)),];
          this.spoc_tree_data[0].children = [];
          this.rawcities = [];
          this.team_wise_spoc_ids = [];
          var unique = response.data.spocs
            .map((name) => {
              return {
                count: 1,
                name: name.city,
              };
            })
            .reduce((a, b) => {
              a[b.name] = (a[b.name] || 0) + b.count;
              return a;
            }, {});
          for (var key in unique) {
            if (unique.hasOwnProperty(key)) {
              this.rawcities.push(key);
              var obj = {
                id: key,
                label: key,
                children: [],
              };
              this.spoc_tree_data[0].children.push(obj);
            }
          }
          response.data.spocs.forEach((server_spoc) => {
            this.spoc_tree_data.forEach((bde) => {
              bde.children.forEach((bdechid) => {
                if (bdechid.label === server_spoc.city) {
                  var obj = {
                    id: server_spoc.id,
                    label: server_spoc.full_name,
                  };
                  bdechid.children.push(obj);
                }
              });
            });
            this.team_wise_spoc_ids.push(server_spoc.id);
          });
          this.spoc_tree_data[0].children = this.sortNestedArrayAlphabetically(
            this.spoc_tree_data[0].children
          );
          this.getGmeetData(1);
        })
        .catch((error) => {
          this.handleError(error);
        });
    },
    showSearch() {
      this.search_criteria = [];
      this.search_criteria.push("divider");
      if(this.selected_team != ''){
        this.search_criteria.push(this.selected_team);
      }
      
      this.search_criteria.push("divider");
      this.selected_spoc_ids.forEach((sort) => {
        if (sort === "All") {
            this.spoc_tree_data[0].children.forEach((child) => {
              child.children.forEach((acutal_spoc) => {
                this.search_criteria.push(acutal_spoc.label);
              });
            });
        } else {
          let city = false;
          this.spoc_tree_data[0].children.forEach((child) => {
            if (child.id === sort) {
              child.children.forEach((acutal_spoc) => {
                this.search_criteria.push(acutal_spoc.label);
              });
              city = true;
            }
          });
          if (city) {
            return true;
          } else {
            this.spoc_tree_data[0].children.forEach((child) => {
              child.children.forEach((acutal_spoc) => {
                if (acutal_spoc.id == sort) {
                  this.search_criteria.push(acutal_spoc.label);
                }
              });
            });
          }
        }
      });

      this.search_criteria.push("divider");
      if(this.selected_status != ''){
        this.search_criteria.push(this.selected_status);
      }

    },
    getColor(status) {
      if (status == "Approve") {
        return "success";
      }
      if (status == "Reject") {
        return "danger";
      }
    },
    changeStatus(row_data) {
      this.popup_data = [];
      this.popup_data.push(row_data);
      this.show_status_popup = true;
    },
    updateActivityStatus(id, status) {
      this.$vs.loading();
      let changed_status = "";
      if (status == 'Approve') {
        changed_status = "Approved"
      }
      if (status == 'Reject') {
        changed_status = "Rejected"
      }
      let obj = {
        google_meet_id: id,
        approval_status: changed_status
      }
      console.log(obj);
      let url = `https://mfcomms.2x2.ninja/api/approveOrRejectGoogleMeetVisit`;
      axios
        .post(url,obj, {
          headers: {
            Authorization: `Bearer ${localStorage.userAccessToken}`,
          },
        })
        .then((response) => {
          console.log("updateActivityStatus", response);
          if(response.data == 'success'){
            this.$vs.notify({
              title: "Status Updated Successfully",
              color: "success",
            });
          }
          if(response.data.status == 'error'){
            this.$vs.notify({
              title: response.data.message,
              color: "danger",
            });
          }
          this.$vs.loading.close();
          this.getGmeetData(1);
          this.show_status_popup = false;
        })
        .catch((error) => {
          this.$vs.loading.close();
          this.handleError(error);
        });
    },

    showTreeBox() {
      return this.spoc_tree_data;
    },
    getSpocs(val) {
      let url = ''
      if (val == 'SR') {
        url = `${constants.SERVER_API}getSRSpocs`;
      } else if (val == 'CM') {
        url = `${constants.SERVER_API}getSpocs`;
      }
      axios
        .get(url, {
          headers: { Authorization: `Bearer ${localStorage.userAccessToken}` },
        })
        .then((response) => {
          console.log("response.data.spocs", response.data.spocs,logged_in_user_name);
          let logged_in_user_name = response.data.logged_in_user_name
          this.spocs_data = response.data.spocs;
          this.cities_list = [...new Set(response.data.spocs.map((item) => item.city)),];
          this.spoc_tree_data[0].children = [];
          this.rawcities = [];
          this.team_wise_spoc_ids = [];
          
          if(this.logged_in_team == 'CM' || this.logged_in_team == 'SR'){
            const spoc = [
            ...new Set(response.data.spocs.map((item) => item)),
            ];
            spoc.forEach((item) => {
            if (item != null) {
              if (logged_in_user_name == item.full_name) {
                var obj = {
                id: item.city,
                label: item.city,
                children: [],
              };
              this.spoc_tree_data[0].children.push(obj);
              this.spoc_tree_data.forEach((bde) => {
              bde.children.forEach((bdechid) => {
                if (bdechid.label === item.city) {
                  var obj = {
                    id: item.id,
                    label: item.full_name,
                  };
                  bdechid.children.push(obj);
                }
              });
            });
            this.team_wise_spoc_ids.push(item.id);
            this.selected_spoc_ids.push(this.spoc_tree_data[0].children[0].children[0].id);
            }
            }
          });
          this.getGmeetData(1);
          }else{
            var unique = response.data.spocs
            .map((name) => {
              return {
                count: 1,
                name: name.city,
              };
            })
            .reduce((a, b) => {
              a[b.name] = (a[b.name] || 0) + b.count;
              return a;
            }, {});
          for (var key in unique) {
            if (unique.hasOwnProperty(key)) {
              this.rawcities.push(key);
              var obj = {
                id: key,
                label: key,
                children: [],
              };
              this.spoc_tree_data[0].children.push(obj);
            }
          }
          
          response.data.spocs.forEach((server_spoc) => {
            console.log('server_spocss', server_spoc);
            this.spoc_tree_data.forEach((bde) => {
              bde.children.forEach((bdechid) => {
                if (bdechid.label === server_spoc.city) {
                  // var obj = {
                  //   id: server_spoc.id,
                  //   label: server_spoc.full_name,
                  // };
                  // bdechid.children.push(obj);
                  if(this.logged_in_spoc_id == 132 ||this.logged_in_spoc_id == 937 || this.logged_in_spoc_id == 39){
                      let obj = {
                      id:  server_spoc.id,
                      label: server_spoc.full_name,
                    };
                    bdechid.children.push(obj);
                  }
                   else{
  
                    if(server_spoc.full_name.substring(0,1) != 'X'){
                      let obj = {
                        id:  server_spoc.id,
                        label:  server_spoc.full_name,
                      };
                      bdechid.children.push(obj);
                    }
                   }
                }
              });
            });
            this.team_wise_spoc_ids.push(server_spoc.id);
          });
          this.spoc_tree_data[0].children = this.sortNestedArrayAlphabetically(
            this.spoc_tree_data[0].children
          );
        }
        })
        .catch((error) => {
          this.handleError(error);
        });
    },
    unixTimestampConvert(value) {
      var dateString = moment.unix(value).format("hh:mm");
      return dateString;
    },
    getMinutes(val){
      const h = Math.floor(val / 3600).toString().padStart(2,'0'),
          m = Math.floor(val % 3600 / 60).toString().padStart(2,'0'),
          s = Math.floor(val % 60).toString().padStart(2,'0');
    
      return h + ':' + m + ':' + s;
    },
    unixDateConvert(value) {
      let date_value = moment.unix(value).format("DD-MMM-YYYY");
      return date_value;
    },
    getDate(date) {
      var datum = Date.parse(date);
      return datum/1000;
    },
    getGmeetData(page) {
      console.log("this.dateRange", this.dateRange);
      let end_date = '';
      let start_date = '';
      if (
        this.convertToLocalTimestamp(this.dateRange.startDate) != "Invalid date" &&
        this.convertToLocalTimestamp(this.dateRange.endDate) != "Invalid date"
      ) {
        start_date = this.convertToLocalTimestamp(this.dateRange.startDate);
        end_date = this.convertToLocalTimestamp(this.dateRange.endDate);
      }
      if (this.cities_list.includes(this.selected_spoc_ids[0])) {
        let city = this.selected_spoc_ids[0];
        this.selected_spoc_ids = [];
        for (let i = 0; i < this.spocs_data.length; i++) {
          const element = this.spocs_data[i];
          if (element.city == city) {
            this.selected_spoc_ids.push(element.id);
          }
        }
      }
      let obj = {
        meeting_date_from: start_date,
        meeting_date_to: end_date,
        approval_status: '',
        spoc_ids: ''
      };
if(this.can_id != ''){
        obj['can_id'] = this.can_id
      }
      if (this.selected_status == '') {
        obj.approval_status = 'all'
      } else {
        obj.approval_status = this.selected_status;
      }
      if(this.selected_spoc_ids.length != 0){
        obj.spoc_ids = this.selected_spoc_ids.join();
      }else{
        obj.spoc_ids = this.team_wise_spoc_ids.join();
      }
      console.log(obj, page);
      this.$vs.loading();
      axios
        .get(
          `https://mfcomms.2x2.ninja/api/getGoogleMeetVisits?page=${page}`,
          {
            params: obj,
            headers: {
              Authorization: `Bearer ${localStorage.userAccessToken}`,
            },
          }
        )
        .then((response) => {
          this.tablelinks = response.data.last_page;
          this.Gmeet_data = response.data.data;
          this.$vs.loading.close();
        })
        .catch((error) => {
          this.handleError(error);
          this.$vs.loading.close();
        });
    },
    convertToLocalTimestamp(val){
        var d = new Date(val);
        d.setHours(0,0,0,0);
        return this.getDate(d.toLocaleString('en-US', { timeZone: 'Asia/Kolkata' }));
    },
    refreshFilterData() {
      this.tablelinks = 0;
      this.Gmeet_data = [];
      this.search_criteria = [];
      this.selected_team = '';
      this.selected_status = ''
      this.team_wise_spoc_ids = [];
      this.selected_spoc_ids = [];
      this.dateRange = {
        startDate: new Date(new Date().getFullYear(), new Date().getMonth(), 1),
        endDate: new Date(new Date().getFullYear(), new Date().getMonth()+1, 0),
      }
      if (this.getTeam("GM")) {
      if (localStorage.getItem('sub_team') === 'CM' && this.logged_in_team == 'GM') {
        this.selected_team = 'CM'
      }else if (localStorage.getItem('sub_team') === 'SR' && this.logged_in_team == 'GM') {
        this.selected_team = 'SR'
      }else if(localStorage.getItem('sub_team') === 'GM' && localStorage.getItem('team') === 'GM'){
        this.selected_team = 'CM'
        return
      }
      this.team_dropdown = true;
      this.getGmReportingSpocs();
    }else if (this.getTeam("CM")){
      this.selected_team = 'CM'
      this.team_dropdown = true;
      this.selected_spoc_ids.push(this.spoc_tree_data[0].children[0].children[0].id);
    }else if (this.getTeam("SR")){
      this.selected_team = 'SR'
      this.team_dropdown = true;
      this.selected_spoc_ids.push(this.spoc_tree_data[0].children[0].children[0].id);
    }else if (this.getTeam("Admin")) {
      this.getGmeetData(1);
    }
    },
    async openPopupEmit(payload) {
      this.openBigPopup(payload.mwb_id);
    },
  },
};
</script>
  
<style>
.TimePicker .display-time {
  border: 1px solid #fff;
  width: 150px !important;
  height: 37px !important;
  padding: 0.3em 0.5em;
  font-size: 1em;
}

.headr {
  color: gray;
  font-size: 25px;
  margin-bottom: 2%;
}

.gap {
  margin-bottom: 4%;
}

.inicolor {
  color: gray;
}

.cardtext {
  color: gray;
  font-size: 20px;
}

.tree3 {
  padding: 10px;
  box-sizing: border-box;
  border: 1px dotted #ccccdd;
  overflow: auto;
  height: 500px;
  width: 300px;
}

.vs-dropdown--menu {
  width: 300px;
}

.a-icon {
  outline: none;
  text-decoration: none !important;
  display: flex;
  align-items: center;
  justify-content: center;
}

.gap {
  margin-right: 1%;
}

.examplex {
  display: flex;
  align-items: center;
  justify-content: center;
}

.a-icon {
  outline: none;
  text-decoration: none !important;
  display: flex;
  align-items: center;
  justify-content: center;
}

i {
  font-size: 18px;
}

.vl {
  border-left: 1px solid black;
  height: 35px;
}

#style-1::-webkit-scrollbar-track {
  border-radius: 10px;
  background-color: #f5f5f5;
}

.scrollbar {
  overflow-y: scroll;
}

.vs__dropdown-menu {
  color: black;
}

.vs__dropdown--menu {
  width: 300px;
}

.a-iconn {
  padding: 0 10px 0 10px;
  height: 36px;
  outline: none;
  text-decoration: none !important;
  border: 1px solid #dddddd;
  border-radius: 5px;
  background-color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  color: #bfbfbf
}

.dropp-down {
  width: 100%
}

.a-iconn>.material-icons {
  margin-left: auto;
  color: #bfbfbf
}

.dropdown-cm {
  width: 15%
}
</style>